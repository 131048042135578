import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";

function CustomDatePicker({
  label,
  value,
  onChange,
  minDate,
  maxDate,
  disableFuture,
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        value={value ? value : null}
        onChange={(newValue) => onChange(newValue)}
        minDate={minDate}
        maxDate={maxDate}
        format="YYYY-MM-DD"
        disableFuture={disableFuture}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  );
}

export default CustomDatePicker;
