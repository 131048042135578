/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";

export default function data(rows, totals) {
  const columns = [
    { Header: "date", accessor: "date", align: "center" },
    { Header: "registrations", accessor: "registrations", align: "center" },
    { Header: "FTD Count", accessor: "ftdCount", align: "center" },
    { Header: "RTD Count", accessor: "rtdCount", align: "center" },
    { Header: "DPS Count", accessor: "dpCount", align: "center" },
    { Header: "FTD Amount", accessor: "ftdAmount", align: "center" },
    { Header: "RTD Amount", accessor: "rtdAmount", align: "center" },
    { Header: "DPS Amount", accessor: "dpAmount", align: "center" },
    { Header: "DPS AVG", accessor: "dpAvg", align: "center" },
    { Header: "WDS Count", accessor: "wdCount", align: "center" },
    { Header: "WDS Amount", accessor: "wdAmount", align: "center" },
    { Header: "WDS AVG", accessor: "wdAvg", align: "center" },
  ];
  rows.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
  const rowsElements = rows
    ? [...rows, totals].map((row, index) => {
        return columns.reduce((res, item) => {
          return {
            ...res,
            [item.accessor]:
              index === rows.length ? (
                <MDTypography variant="body3" color="info" fontWeight="medium">
                  {item.accessor === "date" ? "TOTAL" : row[item.accessor] || 0}
                </MDTypography>
              ) : (
                <MDTypography variant="body3" color="text" fontWeight="medium">
                  {row[item.accessor] || 0}
                </MDTypography>
              ),
          };
        }, {});
      })
    : [];

  return {
    columns,
    rows: rowsElements,
  };
}
