import { createQuery } from "./helpers/functions";
import useRequestApi from "./helpers/useRequestApi";

const getDepositsTableData = (params) => {
  var url = `/getDeps?${createQuery(params)}`;
  return useRequestApi(url, "GET", null, "json").then(
    (props) => {
      return Promise.resolve(props);
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export { getDepositsTableData };
