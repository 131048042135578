import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

function CustomSelect({
  options,
  label,
  value,
  onChange,
  placeholder,
  multiple = true,
}) {
  if (!options) {
    options = [];
  }
  return (
    <Autocomplete
      multiple={multiple}
      options={options || []}
      getOptionLabel={(option) => option.label}
      value={value}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          variant="standard"
        />
      )}
      sx={{
        m: 1,
        margin: 0,
        minWidth: 300,
        "& .MuiOutlinedInput-notchedOutline": {
          alignSelf: "center",
          minHeight: 50,
        },
      }}
    />
  );
}

export default CustomSelect;
