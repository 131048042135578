import { useAsyncDebounce } from "react-table";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "templates/LayoutContainers/DashboardLayout";
import DashboardNavbar from "templates/Navbars/DashboardNavbar";

import DataTable from "templates/Tables/DataTable";

// Data
import depositsTableData from "layouts/deposits/data/depositsTabelData";
import CustomDatePicker from "components/CustomDatePicker";
import { useEffect, useRef, useState } from "react";
import CustomSelect from "components/CustomSelect";
import { getDepositsTableData } from "models/deposits";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import CustomRadioButtons from "components/CustomRadioButton";
import { useMaterialUIController } from "context";
import { getTimeZonesList } from "models/timeZones";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { logout } from "models/authentication";

dayjs.extend(utc);
dayjs.extend(timezone);

const Deposits = () => {
  const currencyList = [
    { label: "USD", value: "USD" },
    { label: "BRL", value: "BRL" },
    { label: "AZN", value: "AZN" },
    { label: "GBP", value: "GBP" },
    { label: "LTC", value: "LTC" },
    { label: "BTC", value: "BTC" },
    { label: "ETH", value: "ETH" },
    { label: "USDT", value: "USDT" },
  ];
  const userSources = [
    { label: "Aff Tag", value: "affTag" },
    { label: "Bonus Code", value: "bonusCode" },
    { label: "Organic", value: "organic" },
  ];
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [timeZonesList, setTimeZonesList] = useState([]);
  const [filtersData, setFiltersData] = useState(null);
  const [selectedDateStart, setSelectedDateStart] = useState(null);
  const [selectedDateEnd, setSelectedDateEnd] = useState(null);
  const [timeZone, setTimeZone] = useState({ label: "UTC +0", value: 0 });
  const [selectedSources, setSelectedSources] = useState([]);
  const [selectedPartners, setSelectedPartners] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [searchUserId, setSearchUserId] = useState(null);
  const [searchAffTag, setSearchAffTag] = useState(null);
  const [searchBonusCode, setSearchBonusCode] = useState(null);
  const [depositsData, setDepositsData] = useState({ columns: [], rows: [] });
  const isLoading = useRef(false);

  const convertDate = (date, timeZone, addDays = 0) => {
    if (!date) return null;

    return dayjs(date)
      .add(addDays, "day")
      .tz(timeZone === "UTC +0" ? "Europe/London" : timeZone, true)
      .toISOString()
      .split(".")[0];
  };

  const uploadDepositsData = useAsyncDebounce(async () => {
    if (!isLoading.current) {
      isLoading.current = true;
      const { data, totals, info, error, status } = await getDepositsTableData({
        startDate: convertDate(selectedDateStart, timeZone.label),
        endDate: convertDate(selectedDateEnd, timeZone.label, 1),
        sources: selectedSources
          ? selectedSources.map((item) => item.value)
          : null,
        partners: selectedPartners
          ? selectedPartners.map((item) => item.value)
          : null,
        userId: searchUserId,
        affTag: searchAffTag,
        bonusCode: searchBonusCode,
        currency,
      });

      if (error) {
        if (status === 401) {
          logout();
        }
        isLoading.current = false;
        return;
      }

      setFiltersData(info);
      setDepositsData(depositsTableData(data || [], totals));
    }
  }, 100);

  const getTimeZones = useAsyncDebounce(async () => {
    const { zones } = await getTimeZonesList();

    setTimeZonesList(
      (zones || []).map((zone) => ({
        label: zone.zoneName,
        value: zone.gmtOffset,
      }))
    );
  }, 100);

  useEffect(() => {
    uploadDepositsData();
  }, []);

  useEffect(() => {
    getTimeZones();
  }, []);

  const onSearch = () => {
    uploadDepositsData();
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox
              display="flex"
              justifyContent="flex"
              gap={4}
              alignItems="left"
              color="dark"
              p={1.5}
              mb={3}
            >
              <MDBox
                display="flex"
                justifyContent="flex-start"
                flexDirection="column"
                maxWidth={300}
                gap={2}
              >
                <CustomDatePicker
                  label="Start Date"
                  value={selectedDateStart}
                  onChange={setSelectedDateStart}
                  disableFuture={false}
                />
                <CustomDatePicker
                  label="End Date"
                  value={selectedDateEnd}
                  onChange={setSelectedDateEnd}
                  disableFuture={false}
                />
                <CustomSelect
                  label="Time Zone"
                  multiple={false}
                  options={timeZonesList}
                  value={timeZone}
                  onChange={setTimeZone}
                  placeholder="Choose options..."
                />
              </MDBox>
              <MDBox
                display="flex"
                flexDirection="column"
                justifyItems="start"
                width="20rem"
                gap={2}
              >
                <MDInput
                  label="Search by userId"
                  placeholder="UserId..."
                  value={searchUserId || ""}
                  size="big"
                  fullWidth
                  onChange={({ currentTarget }) => {
                    setSearchUserId(currentTarget.value);
                  }}
                />
                <MDInput
                  label="Search by Aff Tag"
                  placeholder="Tag..."
                  value={searchAffTag || ""}
                  size="big"
                  fullWidth
                  onChange={({ currentTarget }) => {
                    setSearchAffTag(currentTarget.value);
                  }}
                />
                <MDInput
                  label="Search by Bonus Code"
                  placeholder="Code..."
                  value={searchBonusCode || ""}
                  size="big"
                  fullWidth
                  onChange={({ currentTarget }) => {
                    setSearchBonusCode(currentTarget.value);
                  }}
                />
              </MDBox>
              <MDBox
                display="flex"
                flexDirection="column"
                justifyItems="center"
                justifyContent="center"
                maxWidth={300}
                gap={2}
              >
                <CustomSelect
                  label="Source"
                  multiple={true}
                  options={userSources || []}
                  value={selectedSources || []}
                  onChange={setSelectedSources}
                  placeholder="Choose options..."
                />
                <CustomSelect
                  label="Partners"
                  multiple={true}
                  options={filtersData?.partners || []}
                  value={selectedPartners || []}
                  onChange={setSelectedPartners}
                  placeholder="Choose options..."
                />
              </MDBox>
              <MDBox
                display="flex"
                flexDirection="column"
                justifyItems="center"
                maxWidth={350}
              >
                <CustomRadioButtons
                  title="Currency"
                  options={currencyList}
                  onChange={setCurrency}
                />
              </MDBox>
            </MDBox>
            <MDBox
              display="flex"
              flexDirection="column"
              justifyItems="center"
              maxWidth={100}
              ml={1.5}
            >
              <MDButton
                onClick={onSearch}
                disabled={false}
                variant="gradient"
                size="small"
                color={darkMode ? "light" : "dark"}
              >
                Search
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
        <Grid container spacing={6} mt={2}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Deposits Table
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{
                    columns: depositsData.columns,
                    rows: depositsData.rows,
                  }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default Deposits;
