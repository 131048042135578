export const getCookie = (cname) => {
  var name = cname + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1);
    if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
  }
  return "";
};

export const setCookie = (name, val) => {
  var d = new Date();
  d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
  const expires = "expires=" + d.toUTCString();
  document.cookie = name + "=" + val + "; " + expires;
};

export const createQuery = (data) => {
  var request = "";
  for (var key in data) {
    if (!key || !data[key]) {
      continue;
    }
    if (Array.isArray(data[key]) && data[key].length) {
      request += key + "=" + JSON.stringify(data[key]) + "&";
    } else if (data[key] !== "") {
      request += key + "=" + data[key] + "&";
    }
  }
  if (request[request.length - 1] === "&") {
    request = request.substring(0, request.length - 1);
  }
  return request;
};
