import { useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

function CustomRadioButtons({ title, options, onChange }) {
  const [selectedValue, setSelectedValue] = useState(options[0]?.value);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    onChange(event.target.value);
  };

  return (
    <FormControl
      sx={{
        display: "flex",
        // flexDirection: 'row',
        alignItems: "center",
        justifyItems: "center",
        gap: 2,
      }}
    >
      <FormLabel>{title}</FormLabel>
      <RadioGroup
        row
        sx={{
          justifyContent: "center",
        }}
        value={selectedValue}
        onChange={handleChange}
      >
        {options.map((option, index) => {
          return (
            <FormControlLabel
              key={index}
              value={option.value}
              control={<Radio />}
              label={option.label}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}

export default CustomRadioButtons;
