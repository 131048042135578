import { useAuth } from "layouts/authentication/components/AuthProvider";
import vars from "./constants";

const useRequestApi = async (url, method, data) => {
  if (url === "") {
    return null;
  }

  const accessToken = localStorage.getItem("session_key");
  let headers = {
    "Content-Type": "application/json",
    Authorization: accessToken ? `Bearer ${accessToken}` : "",
  };

  let fullURL = `${vars.API_BASE_URL}${url}`;

  const options = {
    method,
    headers,
  };

  if (data) {
    if (type === "GET") {
      fullURL += `?${createQuery(data)}`;
    } else {
      options.body = JSON.stringify(data);
    }
  }

  try {
    const response = await fetch(fullURL, options);

    if (response.status !== 200) {
      return {
        error: true,
        status: response.status,
      };
    }
    return response.json();
  } catch (err) {
    console.error(err);
    return {
      error: true,
      status: 500,
    };
  }
};
export default useRequestApi;
