import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyANmL14W8SiXLS_e_VEp9gb-pyeTeJAukI",
  authDomain: "analytics-auth-e9186.firebaseapp.com",
  projectId: "analytics-auth-e9186",
  storageBucket: "analytics-auth-e9186.firebasestorage.app",
  messagingSenderId: "66563874861",
  appId: "1:66563874861:web:8416ff3bcd35bc463c18a4",
};

initializeApp(firebaseConfig);
export const auth = getAuth();

export const login = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    const idToken = await userCredential.user.getIdToken();
    return {
      success: true,
      error: null,
      idToken,
    };
  } catch (error) {
    if (/missing-email|invalid-email/.test(error.message)) {
      return {
        success: false,
        error: "email",
      };
    } else if (/missing-password|invalid-credential/.test(error.message)) {
      return {
        success: false,
        error: "pass",
      };
    } else {
      console.error("Login failed:", error);
      return {
        success: false,
        error: "unknown",
      };
    }
  }
};

export const logout = async () => {
  try {
    await signOut(auth);
    localStorage.removeItem("session_key");
  } catch (error) {
    console.error("Logout failed:", error);
  }
};
