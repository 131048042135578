import React, { createContext, useContext, useEffect, useState } from "react";
import { auth } from "../../../../models/authentication";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  const initUser = (user) => {
    if (user) {
      setCurrentUser({ ...user });
      setUserLoggedIn(true);
      localStorage.setItem("session_key", user.accessToken);
    } else {
      setCurrentUser(null);
      setUserLoggedIn(false);
      localStorage.setItem("session_key", null);
    }
    setLoading(false);
  };
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(initUser);
    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    userLoggedIn,
    loading,
  };
  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
