export const getTimeZonesList = async () => {
  // MRQZGJTG9KWR
  let fullURL = `https://api.timezonedb.com/v2.1/list-time-zone?key=MRQZGJTG9KWR&format=json`;

  const options = {
    method: "GET",
  };

  try {
    const response = await fetch(fullURL, options);

    if (response.status !== 200) {
      return [];
    }
    return response.json();
  } catch (err) {
    console.error(err);
    return [];
  }
};
